import EVOLUTIONActions from './EVOLUTIONActions'

export default class BTGActions extends EVOLUTIONActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'BTG-SLOT-025',
      platform: 'BTG',
    }

    this.gameName = 'Star Clusters'
  }
}
