import PlatformActions from './PlatformActions'

export default class PPSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'PP-SLOT-082',
      platform: 'PP',
    }

    this.gameName = 'Sweet Bonanza'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      gameName: this.gameName,
      // gameCode: 'PP-SLOT-036',
      userId,
      // platform: 'PP',
      platformTxId: txnId, // ex: 61764eaa91c65d06c01968cd
      roundId: `${roundId}`, // ex: 10755280720215
      betType: null,
      currency,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      gameName: this.gameName,
      // gameCode: 'PP-SLOT-036',
      userId,
      // platform: 'PP',
      platformTxId: `${roundId}`, // ex: 10755280720215
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // ex: 2021-10-01T13:11:16.017+08:00
      roundId: `${roundId}`,
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'SLOT',
      // gameCode: 'PP-SLOT-036',
      userId,
      // platform: 'PP',
      platformTxId: txnId, // ex: 6175b1d391c65d06c0b74fbf
      roundId: `${roundId}`,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: this.constantTxnData.platform,
      currency,
      userId,
      amount: betAmount,
      promotionTxId: `${promotionTxId}`, // 663316e474062c4224d1c2b5
      promotionId: '1026',
      promotionTypeId: '8',
      txTime, // 2024-05-02T12:30:28.592+08:00
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
