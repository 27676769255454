import PlatformActions from './PlatformActions'

export default class CGEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'CG-EGAME-001',
      platform: 'CG',
    }

    this.gameName = 'Lucky5'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      mtcode,
      betTime,
      betAmount,
      balance,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `347${txnId}084840`, // 347 5088547 084840, 347 5088548 084840, 347 5088552 084840
      roundId: `347${txnId}084840`, // 3475088547084840, 3475088548084840, 3475088552084840
      betType: null,
      currency,
      betTime, // 2025-01-16T09:21:18.000+08:00
      betAmount,
      gameInfo: {
        beforeBalance: `${balance}`,
        afterBalance: `${balance - betAmount}`,
        'bet-mtcode': `mtcode-bet-${mtcode}`, // mtcode-bet-5061795923
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      mtcode,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
      balance,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `347${txnId}084840`,
      roundId: `347${txnId}084840`,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime,
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        beforeBalance: `${balance - betAmount}`,
        afterBalance: `${balance - betAmount + winAmount}`,
        'bet-mtcode': `mtcode-bet-${mtcode}`,
        'endround-mtcode': `mtcode-endround-${mtcode + 1}`,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      mtcode,
      betAmount,
      balance,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `347${txnId}084840`,
      roundId: `347${txnId}084840`,
      gameInfo: {
        beforeBalance: `${balance}`,
        afterBalance: `${balance - betAmount}`,
        'bet-mtcode': `mtcode-bet-${mtcode}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getVoidSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `347${txnId}084840`,
      roundId: `347${txnId}084840`,
      betAmount,
      updateTime,
      voidType: 2,
    }))

    const message = {
      action: 'voidSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
