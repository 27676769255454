import PlatformActions from './PlatformActions'

export default class JOKERActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'JOKER-SLOT-151',
      platform: 'JOKER',
    }

    this.gameName = 'Sizzling Hot'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `B-${roundId}`, // B-g36hbsj5fs1mw
      roundId, // g36hbsj5fs1mw
      betType: null,
      currency,
      betTime, // 2024-10-01T11:14:02.199+08:00
      betAmount,
      isPremium: false,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `B-${roundId}`, // B-g36hbsj5fs1mw
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // 2024-10-01T11:14:10.882+08:00
      roundId, // g36hbsj5fs1mw
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        betInfo: [null],
        settleInfo: JSON.stringify({
          type: 'ne4gq55cpitgg',
        }),
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `B-${roundId}`, // B-g36hbsj5fs1mw
      roundId, // g36hbsj5fs1mw
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      roundId,
      betTime,
      betAmount,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `W-${roundId}`, // W-g365uyinfs1mw
      roundId, // g365uyinfs1mw
      betType: null,
      currency,
      betTime, // 2024-07-10T11:49:30.000+08:00
      txTime: betTime,
      betAmount,
      turnover: 0,
      winAmount,
      updateTime,
      gameInfo: {
        type: 'Main',
      },
      requireAmount: betAmount,
      isPremium: false,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
