import PlatformActions from './PlatformActions'

export default class JDBEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'JDB-EGAME-012',
      platform: 'JDB',
    }

    this.gameName = 'Galaxy Burst'

    this.constantTxnData_betNSettle = {
      gameType: 'EGAME',
      gameName: 'Lucky Color Game',
      gameCode: 'JDB-EGAME-002',
      platform: 'JDB',
    }

    this.constantGameInfo_betNSettle = {
      isEndRound: true,
      hasBonusGame: 0,
      denom: 1.0,
      systemTakeWin: 0,
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 15236986680
      roundId, // 7416449773393
      betType: null,
      currency,
      betTime, // 2024-03-21T14:50:14.768+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId,
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2024-03-21T14:50:36.781+08:00
      roundId,
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {},
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData_betNSettle,
      userId,
      platformTxId: txnId, // 1465655
      roundId, // 5250176426357
      betType: '',
      currency,
      betTime, // 2023-03-06T16:50:12.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2023-03-06T16:50:13.000+08:00
      requireAmount: betAmount,
      gameInfo: this.constantGameInfo_betNSettle,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData_betNSettle,
      userId,
      platformTxId: txnId,
      roundId,
      updateTime,
      betAmount,
      gameInfo: this.constantGameInfo_betNSettle,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
