import PlatformActions from './PlatformActions'

export default class JILISLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameName: 'RomaX',
      gameCode: 'JILI-SLOT-040',
      platform: 'JILI',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 429542192785133660
      roundId: `${roundId}`, // 24548629502092191
      betType: null,
      currency,
      betTime, // 2021-10-01T14:09:37.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2021-10-01T14:09:37.230+08:00
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId: `${roundId}`,
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
