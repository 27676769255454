import PlatformActions from './PlatformActions'

export default class JDBTABLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'JDB-TABLE-006',
      platform: 'JDB',
    }

    this.gameName = 'Dragon Tiger - Joker Bonus'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 2068549
      roundId, // 2275092782
      betType: null,
      currency,
      betTime, // 2024-01-23T14:00:10.429+08:00
      betAmount,
      jackpotBetAmount: 0,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 2068549
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2024-01-23T14:00:45.836+08:00
      roundId, // 2275092782
      betType: null,
      betTime,
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      jackpotBetAmount: 0,
      gameInfo: {},
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
