import PlatformActions from './PlatformActions'

export default class YESBINGOBINGOActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'BINGO',
      gameCode: 'YesBingo-BINGO-007',
      platform: 'YESBINGO',
    }

    this.gameName = 'MoneyBingo'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 1107057964
      roundId: `${roundId}`, // 6324324
      betType: null,
      currency,
      betTime, // 2022-07-07T18:01:45.835+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 1107057964
      refPlatformTxI: null,
      settleType: 'platformTxId',
      updateTime, // 2022-07-19T09:49:34.071+08:00
      roundId: `${roundId}`, // 6324324
      betType: null,
      betTime, // 2022-07-19T09:49:26.365+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        playSeq: `${roundId}`,
        round: '0',
        hasBonusGame: false,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`, // 1107057964
      roundId: `${roundId}`, // 6324324
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
