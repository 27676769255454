import PlatformActions from './PlatformActions'

export default class ILOVEUActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameName: 'Dragon Fortune',
      gameCode: 'ILOVEU-SLOT-040',
      platform: 'ILOVEU',
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 324596 5789668311040 / 324596 5869133594624 / 324597 0577269194752
      roundId, // NTY2NTMxNC8zMjQ1OT Y1Nzg5NjY4MzExMDQw  / NTY2NTMxNC8zMjQ1OT cwMzkxNTI4NjM2NDE2
      betType: null,
      currency,
      betTime, // 2024-07-10T11:49:30.000+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
      requireAmount: betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
      updateTime,
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }
}
