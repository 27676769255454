import CGEGAMEActions from './CGEGAMEActions'

export default class CGSLOTActions extends CGEGAMEActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'CG-SLOT-007',
      platform: 'CG',
    }

    this.gameName = '1c dafudacai-5Treasures'
  }
}
