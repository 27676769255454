import PlatformActions from './PlatformActions'

export default class YLEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'YL-EGAME-001',
      platform: 'YL',
    }

    this.gameName = 'Fruit'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'EGAME',
      gameName: this.gameName,
      // gameCode: 'YL-EGAME-004',
      userId,
      // platform: 'YL',
      platformTxId: `${roundId}001`, // ex:202112061717233309481479213573001
      roundId, // ex: 202112061717233309481479213573
      betType: null,
      currency,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      betAmount,
      gameInfo: {
        extension1: 'TSTESTyl00wc081688',
        chair: '0',
        roomLevel: '0',
        roomId: '0',
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      updateTime,
      roundId,
      betTime,
      turnover,
      betAmount,
      winAmount,
      profit,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'EGAME',
      gameName: this.gameName,
      // gameCode: 'YL-EGAME-004',
      userId,
      // platform: 'YL',
      platformTxId: `${roundId}001`, // ex:202112061717233309481479213573001
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // ex: 2021-11-02T00:55:56.211+08:00
      roundId, // ex: 202112061717233309481479213573
      betType: null,
      betTime,
      txTime: betTime,
      turnover, // 50
      betAmount, // 50
      winAmount, // 97.5
      gameInfo: {
        extension1: 'TSTESTyl00wc081688',
        gameNumber: roundId,
        chair: '0',
        roomLevel: '0',
        commission: '0.0',
        profit,
        roomId: '0',
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'EGAME',
      // gameCode: 'YL-EGAME-004',
      userId,
      // platform: 'YL',
      platformTxId: `${roundId}001`, // ex:202112061717233309481479213573001
      roundId, // ex: 202112061717233309481479213573
      gameInfo: {
        extension1: 'TSTESTyl00wc081688',
        chair: '0',
        roomLevel: '0',
        roomId: '0',
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
