import PlatformActions from './PlatformActions'

export default class JILITABLEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'TABLE',
      gameCode: 'JILI-TABLE-018',
      platform: 'JILI',
    }

    this.gameName = 'Super Bingo'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      betTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'TABLE',
      gameName: this.gameName,
      // gameCode: 'JILI-TABLE-001',
      userId,
      // platform: 'JILI',
      platformTxId: `${txnId}`, // ex: 383459259544764527
      roundId: `${roundId}`, // ex: 218018720111695
      betType: null,
      currency,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      betAmount,
      gameInfo: {
        domeId: `${roundId}`,
      },
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      winAmount,
      turnover,
      betTime,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'TABLE',
      gameName: this.gameName,
      // gameCode: 'JILI-TABLE-001',
      userId,
      // platform: 'JILI',
      platformTxId: `${txnId}`,
      refPlatformTxId: null,
      settleType: 'roundId',
      updateTime, // ex: 2021-10-01T13:11:16.017+08:00
      roundId: `${roundId}`,
      betType: null,
      betTime, // ex: 2021-10-01T13:11:15.798+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        domeId: `${roundId}`,
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      // gameType: 'TABLE',
      // gameCode: 'JILI-TABLE-001',
      userId,
      // platform: 'JILI',
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      gameInfo: {
        domeId: `${roundId}`,
      },
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }
}
