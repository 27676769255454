import PlatformActions from './PlatformActions'

export default class FCEGAMEActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'EGAME',
      gameCode: 'FC-EGAME-001',
      platform: 'FC',
    }

    this.gameName = 'MONEY TREE DOZER'

    this.constantTxnData_betNSettle = {
      gameType: 'EGAME',
      gameName: 'FA CHAI DOZER',
      gameCode: 'FC-EGAME-002',
      platform: 'FC',
    }
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 64915aae81a09a0b30f75451
      roundId, // 64915aad81a09a0b30f75450
      betType: null,
      currency,
      betTime, // 2023-06-20T15:52:13.000+08:00
      betAmount,
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 64915aae81a09a0b30f75451
      refPlatformTxId: null,
      settleType: 'platformTxId',
      updateTime, // 2023-06-20T15:52:24.244+08:00
      roundId, // 64915aad81a09a0b30f75450
      betType: null,
      betTime, // 2023-06-20T15:52:13.000+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {},
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId,
      roundId,
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
      requireAmount,
    }) => ({
      ...this.constantTxnData_betNSettle,
      userId,
      platformTxId: `${txnId}`, // 4663241255
      roundId: `${roundId}`, // 6871603023
      betType: null,
      currency,
      betTime, // 2021-10-01T14:09:37.000+08:00
      txTime: betTime,
      betAmount, // betAmount.toFixed(1)
      turnover,
      winAmount,
      updateTime,
      requireAmount: requireAmount ?? betAmount,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  // settle_win
  // winAmount: ( betAmount * 2.5 ).toFixed(1)
  // settle_lose
  // winAmount: ( betAmount * 0 ).toFixed(1)
  // turnover: ( betAmount * 1 ).toFixed(1)

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      roundId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData_betNSettle,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${roundId}`,
      updateTime, // ex: 2021-10-01T14:09:37.230+08:00
      betAmount,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  // eslint-disable-next-line class-methods-use-this
  getGiveMessage(txnList) {
    const txns = txnList.map(({
      promotionTxId,
      userId,
      betAmount,
      txTime,
    }) => ({
      platform: this.constantTxnData.platform,
      promotionTxId: `TST${promotionTxId}`,
      promotionId: '336',
      promotionTypeId: '5',
      userId,
      amount: betAmount,
      txTime, // format('YYYY-MM-DDTHH:mm:ss.SSS+08:00')
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
