import PlatformActions from './PlatformActions'

export default class PGSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'PG-SLOT-058',
      platform: 'PG',
    }

    this.gameName = 'Treasures of Aztec'

    this.constantGameInfo = {
      freeGameTransactionId: '',
      isAdjustment: false,
      bonusTransactionId: '',
      isFeature: false,
    }
  }

  getBetNSettleMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: `${txnId}`, // 1443805690186534912
      roundId: `${txnId}`, // 1443805690186534912
      betType: '1',
      currency,
      betTime, // 2021-10-01T13:11:15.798+08:00
      txTime: betTime,
      betAmount,
      turnover,
      winAmount,
      updateTime, // 2021-11-27T08:57:19.650+08:00
      requireAmount: betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'betNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetNSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      betAmount,
      updateTime,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: `${txnId}`,
      roundId: `${txnId}`,
      updateTime, // 2021-10-01T13:11:16.017+08:00
      betAmount,
      gameInfo: this.constantGameInfo,
    }))

    const message = {
      action: 'cancelBetNSettle',
      txns,
    }

    return JSON.stringify(message)
  }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(
      ({
        userId, betAmount, promotionTxId, txTime,
      }) => ({
        platform: this.constantTxnData.platform,
        currency,
        userId,
        amount: betAmount,
        promotionTxId: `CASH-8928-${promotionTxId}-901`, // CASH-9015-64917233-901, CASH-8928-3376318-901
        promotionId: '1103',
        promotionTypeId: '2',
        txTime, // 2024-08-10T00:17:49.543+08:00
      }),
    )

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
