import PlatformActions from './PlatformActions'

export default class FASTSPINSLOTActions extends PlatformActions {
  constructor(formData) {
    super(formData)
    this.formData = formData

    this.constantTxnData = {
      gameType: 'SLOT',
      gameCode: 'FastSpin-SLOT-003',
      platform: 'FASTSPIN',
    }

    this.gameName = 'Roma'
  }

  getBetMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      txnId,
      betTime,
      betAmount,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20220623194459540110134
      roundId: txnId,
      betType: null,
      currency,
      betTime, // 2022-06-23T19:44:59.000+08:00
      betAmount,
      gameInfo: {},
    }))

    const message = {
      action: 'bet',
      txns,
    }

    return JSON.stringify(message)
  }

  getSettleMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
      refPlatformTxId,
      roundId,
      updateTime,
      betTime,
      turnover,
      betAmount,
      winAmount,
      hasFreeSpin,
    }) => ({
      ...this.constantTxnData,
      gameName: this.gameName,
      userId,
      platformTxId: txnId, // 20220623194459999110135: 20220623194459 999 110135
      refPlatformTxId, // 20220623194459540110134: 20220623194459 540 110134
      settleType: 'refPlatformTxId',
      updateTime, // 2022-06-23T19:45:00.073+08:00
      roundId: txnId, // 20220623194459540110134
      betType: null,
      betTime, // 2022-06-23T19:44:59.000+08:00
      txTime: betTime,
      turnover,
      betAmount,
      winAmount,
      gameInfo: {
        ...(winAmount && { refTicketIds: [`${roundId}`] }),
        ...(hasFreeSpin && {
          specialGame: {
            type: 'Free',
            count: 1,
            sequence: 0,
          },
        }),
      },
    }))

    const message = {
      action: 'settle',
      txns,
    }

    return JSON.stringify(message)
  }

  getCancelBetMessage(txnList) {
    const txns = txnList.map(({
      userId,
      txnId,
    }) => ({
      ...this.constantTxnData,
      userId,
      platformTxId: txnId, // 20220623194459540110134
      roundId: txnId,
      gameInfo: {},
    }))

    const message = {
      action: 'cancelBet',
      txns,
    }

    return JSON.stringify(message)
  }

  // getFreeSpinMessage(txnList) {
  //   const txns = txnList.map(({
  //     userId,
  //     txnId,
  //     refPlatformTxId,
  //     roundId,
  //     updateTime,
  //     betTime,
  //     winAmount,
  //     count = 1,
  //     sequence = 1,
  //   }) => ({
  //     ...this.constantTxnData,
  //     gameName: this.gameName,
  //     userId,
  //     platformTxId: txnId, // 20220623194459999110135: 20220623194459 999 110135
  //     refPlatformTxId, // 20220623194459540110134: 20220623194459 540 110134
  //     updateTime,
  //     roundId: refPlatformTxId,
  //     betType: null,
  //     betTime,
  //     turnover: 0,
  //     betAmount: 0,
  //     winAmount,
  //     gameInfo: {
  //       ...(winAmount && { refTicketIds: [`${roundId}`] }),
  //       specialGame: {
  //         type: 'Free',
  //         count,
  //         sequence,
  //       },
  //     },
  //   }))

  //   const message = {
  //     action: 'freeSpin',
  //     txns,
  //   }

  //   return JSON.stringify(message)
  // }

  getGiveMessage(txnList) {
    const { currency } = this.formData

    const txns = txnList.map(({
      userId,
      betAmount,
      promotionTxId,
      txTime,
    }) => ({
      platform: this.constantTxnData.platform,
      currency,
      userId,
      amount: betAmount,
      promotionTxId, // 20220720094043257115902
      promotionId: '812',
      promotionTypeId: '24',
      txTime,
    }))

    const message = {
      action: 'give',
      txns,
    }

    return JSON.stringify(message)
  }
}
